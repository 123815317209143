import { Component, Input } from '@angular/core';
import { ProductFormType } from '../../pages/product-edit/product-edit.model';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'app-shipping-setting',
  templateUrl: './shipping-setting.component.html',
  styleUrls: ['./shipping-setting.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ShippingSettingComponent {
  @Input() isViewMode: boolean = false;
  protected readonly managementPrefix = 'section-product-n-food-management.';
  protected readonly descriptionPrefix = 'text-description.';

  @Input({ required: true }) productForm: ProductFormType;
}
