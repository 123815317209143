import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { ChipsAddEvent } from 'primeng/chips';

import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import { ProductGet } from 'src/app/core/models/interfaces/product/product-detail';
import { ProductFormType } from '../../pages/product-edit/product-edit.model';

@Component({
  selector: 'app-general-info',
  templateUrl: './general-info.component.html',
  styleUrls: ['./general-info.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class GeneralInfoComponent {
  SoctripIcons = SoctripIcons;
  actionPrefix = 'section-action.';
  managementPrefix = 'section-product-n-food-management.';
  descriptionPrefix = 'text-description.';

  @Input({ required: true }) productForm: ProductFormType;
  @Input({ required: true }) attachments: any[];
  @Input({ required: true }) categories: any[];
  @Input() productData: ProductGet;
  @Input() isViewMode: boolean = false;
  @Output() attachmentsChange = new EventEmitter<any[]>();

  onAddChip(event: ChipsAddEvent) {
    const MAX_LENGTH_HASHTAG = 100;
    if (event.value.length > MAX_LENGTH_HASHTAG) {
      this.productForm.hashtags.pop();
      this.productForm.hashtags.push(event.value.substring(0, MAX_LENGTH_HASHTAG));
    }
  }
  onAttachmentsChange(e: any[]) {
    this.attachments = e;
    this.attachmentsChange.emit(this.attachments);
  }

  onSelectCategory(event: any) {
    const type = event.node?.type;
    if (type === 'E_TICKET') {
      const { weight, width, length, height } = this.productForm.parcel;
      this.productForm.parcel = {
        weight: weight || 100,
        width: width || 100,
        length: length || 100,
        height: height || 100,
      };
    }
  }
}
