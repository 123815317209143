import { OnInit } from '@angular/core';
import { Subject, debounceTime } from 'rxjs';
import { EventEmitter, Output } from '@angular/core';
import { Component, Input } from '@angular/core';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import { ProductService } from 'src/app/core/services/product.service';
import { FileService } from 'src/app/core/services/file.service';
import { ShopCatalogControllerService } from '@soctrip/angular-catalog-service';
import { convertCategoriesToTree } from 'src/app/core/utils/convert-categories';
import { ProductSummary } from 'src/app/core/models/interfaces/product/product-summary';
import { AppService } from 'src/app/core/services/app.service';

@Component({
  selector: 'app-add-product-dialog',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss'],
})
export class AddProductComponent implements OnInit {
  protected readonly SoctripIcons = SoctripIcons;
  shopId: string = '';
  @Input({ required: true }) isModal = false;
  @Input({ required: true }) selectedProducts: ProductSummary[] = [];
  @Input() idsDisable: string[] = [];
  @Input() isStock = false;
  @Output() isModalChange = new EventEmitter<boolean>();
  @Output() selectedProductsChange = new EventEmitter<ProductSummary[]>();
  @Output() totalProduct = new EventEmitter<number>();
  @Input() isViewMode = false;
  managementPrefix = 'section-product-n-food-management.';

  _products: ProductSummary[] = [];
  _totalProduct = 0;
  isFetching = false;
  isFetched = false;

  _categories: any[] = [];
  categories: any[] = [];
  pagination = {
    rows: 10,
    page: 0,
  };
  keyword = '';
  keywordChanged = new Subject<string>();

  currentCurrency: string = '';

  constructor(
    private productService: ProductService,
    private shopCatalogService: ShopCatalogControllerService,
    public fileService: FileService,
    private appService: AppService,
  ) {}

  ngOnInit() {
    this.currentCurrency = JSON.parse(localStorage.getItem('currency-conversation-data') || '{}').currency;
    this.appService.getShopData().subscribe((data) => {
      if (data?.id) {
        this.shopId = data.id;
        this._fetchCategories();
        this._fetchProducts(false);
      }
    });

    this.keywordChanged.pipe(debounceTime(800)).subscribe({
      next: (keyword) => {
        this.keyword = keyword;
        this._fetchProducts(true);
      },
    });
  }

  get disableCheckbox() {
    let result = false;
    const productIds = this._products.map((product) => product.id);
    if (this.idsDisable.length === 0) {
      result = false;
    }

    this.idsDisable.forEach((id) => {
      if (productIds.includes(id)) {
        result = true;
      }
    });
    return result;
  }

  _fetchCategories() {
    this.shopCatalogService.shopCatalogsMyShopObjectIdGet(this.shopId, 0, 1000).subscribe({
      next: (res) => {
        if (res?.data?.data) {
          this._categories = convertCategoriesToTree(res.data.data);
        }
      },
    });
  }

  _fetchProducts(resetPage: boolean) {
    if (resetPage) {
      this.onResetPagination();
    }
    if (this.isFetched) {
      this.isFetching = true;
    }

    const catalog_ids = this.categories?.map((category) => category.id).join('|');
    const filter = [
      catalog_ids ? `catalog_ids==${catalog_ids}` : '',
      this.keyword ? `name@=${this.keyword}` : '',
      this.isStock ? 'quantity>0' : '',
    ]
      .filter((i) => i)
      .join(',');

    this.productService
      .shopsMyShopSearchGet(this.shopId, this.pagination.page, this.pagination.rows, filter, '-updated_at|time')
      .subscribe({
        next: (res: any) => {
          this._products = res.data.data;
          this._totalProduct = res.data.totalElement;
          this.totalProduct.emit(this._totalProduct);
          if (this.isFetched) {
            this.isFetching = false;
          } else {
            this.isFetched = true;
          }
        },
        error: () => {
          if (this.isFetched) {
            this.isFetching = false;
          } else {
            this.isFetched = true;
          }
        },
      });
  }

  onRemoveCategory(event: any, index: number) {
    event.stopPropagation();
    this.categories.splice(index, 1);
    this._fetchProducts(true);
  }

  onResetPagination() {
    this.pagination = {
      page: 0,
      rows: 10,
    };
  }

  setIsModal(isModal: boolean) {
    this.isModal = isModal;
    this.isModalChange.emit(this.isModal);
  }

  onChangeSelectedProducts(e: any) {
    this.selectedProducts = e;
  }

  onAddProducts() {
    this.setIsModal(false);
    this.selectedProductsChange.emit(this.selectedProducts);
  }

  onCancelAddProduct() {
    this.setIsModal(false);
  }
}
