<div (click)="notificationPanel.toggle($event)" class="relative cursor-pointer hover:text-blue-500">
  <i class="pi pi-bell !text-xl" pBadge value="{{ badgeRead }}" severity="danger"></i>
</div>

<p-overlayPanel #notificationPanel (onHide)="overlayVisible = false">
  <ng-container *ngTemplateOutlet="notifications"></ng-container>
</p-overlayPanel>

<div *ngIf="mobileOverlayVisible" class="xs:hidden fixed top-0 left-0 w-full h-full overflow-y-auto bg-white z-10">
  <ng-container *ngTemplateOutlet="notifications"></ng-container>
</div>

<ng-template #notifications>
  <div class="w-96 p-0 max-xs:w-full -m-5">
    <div class="flex flex-col gap-3 p-4 pb-3">
      <div class="relative flex justify-between w-full">
        <div class="flex flex-row gap-2 justify-start items-center w-full">
          <p>{{ "section-header.notifications" | translate }}</p>
          <span class="bg-error-500 rounded-full text-xs text-white p-1 {{ isSingleDigit(badgeRead) && 'px-2' }}">{{
            badgeRead
          }}</span>
        </div>
        <div class="gap-1 flex flex-row">
          <span class="flex sctr-icon-dots-vertical text-lg cursor-pointer" (click)="op1.toggle($event)"></span>
          <p-overlayPanel #op1>
            <div class="-mx-2 -my-3">
              <button
                class="flex gap-2 items-center w-full py-[9px] px-[10px] hover:bg-gray-200 rounded"
                (click)="markAllReadNotification($event); op1.toggle($event)"
              >
                <i class="sctr-icon-book-open-01 text-base"></i>
                <span class="text-gray-700 text-sm font-medium">{{
                  "section-header.mark-all-as-read" | translate
                }}</span>
              </button>
              <button
                class="flex gap-2 items-center w-full py-[9px] px-[10px] hover:bg-gray-200 rounded"
                (click)="navigateToAccountSettings(); op1.toggle($event)"
              >
                <i class="sctr-icon-settings-02 text-base"></i>
                <span class="text-gray-700 text-sm font-medium">{{ "section-header.settings" | translate }}</span>
              </button>
            </div>
          </p-overlayPanel>
          <span class="flex sctr-icon-x-close text-lg cursor-pointer xs:hidden" (click)="closeDialog()"></span>
        </div>
      </div>
    </div>
    <div class="p-2 space-y-2 xs:max-h-[588px] scroll-auto overflow-x-hidden">
      <ng-container *ngFor="let item of notificationList; let i = index">
        <div
          class="space-x-3 w-full flex flex-col flex-nowrap {{ !item._read ? 'bg-primary-50' : null }} p-2 rounded-md"
          (mouseenter)="onMouseEnter(i)"
          (mouseleave)="onMouseLeave()"
        >
          <div class="flex justify-start items-start gap-x-4">
            <div class="relative max-w-fit w-full h-full">
              <img
                class="w-11 h-11 rounded-full cursor-pointer"
                onerror="this.src='assets/images/default_user_avatar.png'"
                [src]="getAvatar(item.sender_avatar)"
              />
              <div
                class="absolute bottom-[-1px] right-[-8px] cursor-pointer w-6 h-6 {{
                  getColorIcon(item.icon_color).background
                }} rounded-full flex items-center justify-center"
              >
                <span
                  class="{{ item.icon_image }} text-xs {{ getColorIcon(item.icon_color).color }} text-center"
                ></span>
              </div>
            </div>

            <div
              class="flex flex-col text-sm text-gray-700 font-semibold cursor-pointer"
              (click)="item.web_link && navigationUrl(item.specific_type, item._read, item.web_link, item.id, $event)"
            >
              <div class="font-normal text-sm">
                <div>
                  <div class="font-semibold max-w-[8rem] inline-flex">
                    <span class="truncate">{{ item.sender_full_name }}</span>
                  </div>
                  <div class="inline">{{ " " + item.body }}</div>
                </div>
              </div>
              <span class="text-xs font-normal text-gray-500">
                {{ this.calculateFromNow(item.send_time) }}
              </span>
            </div>
            <button
              class="relative sctr-icon-dots-horizontal text-lg last-of-type:ml-auto transition ease-in-out {{
                showButtonIndex === i ? ' cursor-pointer' : 'text-transparent'
              }} "
              (click)="overlayPanelElement.toggle($event); indexOverlay = i"
            ></button>
            <div></div>
          </div>
          <p-overlayPanel
            #overlayPanelElement
            [appendTo]="'body'"
            [styleClass]="
              'overlayPanel-notifications-custom right-0 mt-0 p-0 before:hidden w-max after:hidden mt-[1px] border-none'
            "
          >
            <div class="-my-3 -mx-2">
              <button
                class="flex gap-2 items-center w-full py-[9px] px-[10px] hover:bg-gray-200 rounded-md"
                (click)="toggleNotification(item.id, $event); overlayPanelElement.toggle($event); indexOverlay = i"
              >
                <i class="sctr-icon-book-open-01 text-base"></i>
                <span class="text-gray-700 w-max text-sm font-medium">{{
                  item._read ? ("section-header.unread" | translate) : ("section-header.read" | translate)
                }}</span>
              </button>
              <button
                class="flex text-system-error-500 gap-2 items-center w-full py-[9px] px-[10px] hover:bg-gray-200 rounded-md"
                (click)="
                  deleteNotification(item.id, $event, item._read); overlayPanelElement.toggle($event); indexOverlay = i
                "
              >
                <i class="sctr-icon-trash-03 text-base"></i>
                <span class="text-system-error-500 w-max text-sm font-medium">{{
                  "section-header.remove" | translate
                }}</span>
              </button>
            </div>
          </p-overlayPanel>
          <div *ngIf="!item._cta_complete && item.cta_action" class="flex justify-end gap-3 py-3 pb-1">
            <ng-container *ngFor="let action of item?.cta_action">
              <app-button
                *ngIf="action?.label === 'Decline' || action?.label === 'Reject'"
                (onClick)="processNotificationAction(action?.post_link, item.id)"
                icon="{{ action?.icon }}"
                label="{{ action?.label }}"
              >
              </app-button>
              <app-button
                *ngIf="action?.label === 'Accept'"
                icon="{{ action?.icon }}"
                (onClick)="processNotificationAction(action?.post_link, item.id)"
                label="{{ action?.label }}"
              >
              </app-button>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
