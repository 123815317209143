import { Component, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { RoleEnum } from 'src/app/core/enum/role.enum';
import { User } from 'src/app/core/models/interfaces/user';
import { AppService } from 'src/app/core/services/app.service';
import { CustomTranslateService } from 'src/app/core/services/custom-translate.service';
import { RoleService } from 'src/app/core/services/role.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  readonly env = environment;
  readonly accountBlancePrefix = 'section-account-balance.';
  isShopActivated = true;
  isSuspension = false;
  status: 'DENIED' | 'NEW' | 'ACCEPTED';
  @Input() isSmallScreen: boolean = false;
  @Input() user: User;
  shopURL = '';
  url = '';

  constructor(
    private appService: AppService,
    private router: Router,
    private roleService: RoleService,
    private translator: CustomTranslateService,
  ) {}

  isPermissionAccountBalance() {
    return this.roleService.userRolesValue.some((role) => [RoleEnum.E_OWNER].includes(role));
  }

  setIsSidebar(isSidebar: boolean) {
    this.appService.setIsSidebar(isSidebar);
  }

  ngOnInit(): void {
    this.shopURL = `${this.env.FE_URL_SALE}`;
    this.url = this.router.url;
    this.router.events.subscribe({
      next: (event) => {
        if (event instanceof NavigationEnd) {
          this.url = event.url;
        }
      },
    });

    this.appService.getShopData().subscribe((data) => {
      if (data) {
        this.isShopActivated = data?.is_market_public;
        this.status = data?.accepted_status;
      }
    });

    this.appService.getIsSuspension().subscribe((isSuspension) => {
      this.isSuspension = isSuspension;
    });
  }

  changeLanguage(language: string) {
    this.translator.setLanguage(language);
  }
}
