import { Directive, Input } from '@angular/core';
import { ProductFormType } from '../pages/product-edit/product-edit.model';
import { AbstractControl, NG_VALIDATORS, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[optionValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: OptionValidatorDirective,
      multi: true,
    },
  ],
})
export class OptionValidatorDirective {
  @Input() productForm: ProductFormType;
  @Input() variantIdx: number;

  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    const options =
      this.variantIdx == 0
        ? this.productForm.pricings.map((pricing) => pricing.value)
        : this.productForm.pricings[0].options.map((option) => option.value);

    if (value && options.includes(value) && control.dirty) {
      return { duplicated: true };
    }
    return null;
  }
}
