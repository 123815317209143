export enum RoleEnum {
  // E_SHOP_SOCTRIP_VOUCHER_RW = 'E_SHOP_SOCTRIP_VOUCHER_RW',
  // E_SHOP_PROMOTION_MANAGE_RW = 'E_SHOP_PROMOTION_MANAGE_RW',
  // E_SHOP_CATALOG_MANAGE_RW = 'E_SHOP_CATALOG_MANAGE_RW',
  // E_SHOP_PRODUCT_MANAGE_RW = 'E_SHOP_PRODUCT_MANAGE_RW',
  // E_SHOP_BUILDER_RW = 'E_SHOP_BUILDER_RW',
  E_OWNER = 'E_OWNER',
  SUPER_ADMIN_SHOP = 'SHOP_ADMIN',
  E_ADMIN_SHOP = 'ADMIN_SHOP',
  E_PRODUCT_MANAGEMENT = 'E_PRODUCT_MANAGEMENT',
  E_PROMOTION_MANAGEMENT = 'E_PROMOTION_MANAGEMENT',
}
