<div class="h-full flex gap-12 justify-between items-center px-6">
  <span class="flex items-center gap-4">
    <i *ngIf="isSmallScreen" class="pi pi-bars p-1 cursor-pointer hover:text-blue-500" (click)="setIsSidebar(true)"></i>
    <div class="flex items-center gap-2">
      <a class="flex items-center gap-2 cursor-pointer" [href]="shopURL">
        <img
          class="inline-block w-auto h-[1.875rem] object-contain object-center"
          src="assets/imgs/logo/{{ env.APP_LOGO }}.svg"
          alt="{{ env.APP_NAME }} Logo"
        />
        <h2 class="text-xl flex items-center gap-1">
          <span class="font-extrabold text-blue-600">{{ env.APP_NAME }}</span>
          <span>Seller</span>
        </h2>
      </a>
      <div
        *ngIf="isSuspension"
        class="rounded-full h-7 border border-warning-600 px-3 text-xs font-medium text-warning-600 bg-orange-50 flex items-center gap-[6px]"
      >
        <img src="/assets/icons/suspension.svg" />
        {{ "section-header.suspension-mode" | translate }}
      </div>
    </div>
  </span>

  <div
    *ngIf="status == 'DENIED' || !isShopActivated"
    class="flex-1 text-xs text-error-600 italic font-medium flex items-end justify-center text-center"
  >
    ***
    <ng-container *ngIf="!isShopActivated && status == 'NEW'">
      {{ "section-header.shop-new" | translate: { appName: env.APP_NAME } }}
    </ng-container>

    <ng-container *ngIf="!isShopActivated && status != 'NEW' && status != 'DENIED'">
      {{ "section-header.shop-not-public" | translate: { appName: env.APP_NAME } }}
    </ng-container>

    <ng-container *ngIf="status == 'DENIED'">
      {{ "section-header.shop-rejected" | translate: { appName: env.APP_NAME } }}
    </ng-container>
    ***
  </div>

  <div class="flex items-center gap-4 lg:gap-6">
    <a
      *ngIf="isPermissionAccountBalance()"
      [routerLink]="['account-balance']"
      pTooltip="{{ accountBlancePrefix  + 'account-balance' | translate | sentenceCase }}"
      tooltipPosition="bottom"
      class="w-10 h-10 flex items-center justify-center hover:bg-gray-100 cursor-pointer rounded-lg"
      [ngClass]="{ 'bg-blue-50': url.startsWith('/account-balance') }"
    >
      <i
        class="sctr-icon-wallet-02 text-xl hover:text-blue-500"
        [ngClass]="url.startsWith('/account-balance') ? 'text-blue-700' : 'text-gray-700'"
      ></i>
    </a>
    <!-- <app-flags-dropdown
      (languageChange)="changeLanguage($event)"
    ></app-flags-dropdown> -->

    <app-notifications></app-notifications>

    <div class="flex items-center gap-3">
      <app-custom-image
        class="w-[1.875rem] h-[1.875rem] overflow-hidden rounded-full"
        [src]="user?.avatar_thumbnail_url || ''"
        alt="Avatar User"
      ></app-custom-image>
      <div *ngIf="user?.full_name" class="grid text-sm font-semibold text-gray-700">
        <span>{{ user?.full_name }}</span>
      </div>
    </div>
  </div>
</div>
