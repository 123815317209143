import { Component } from '@angular/core';

@Component({
  selector: 'app-iframe-approval-product',
  templateUrl: './iframe-approval-product.component.html',
  styleUrls: ['./iframe-approval-product.component.scss']
})
export class IframeApprovalProductComponent {

}
