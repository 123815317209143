import { Component, Input, OnChanges, SimpleChanges, inject, ViewChild } from '@angular/core';
import { Product } from '../../models/product';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { Category } from '../../models/category';
import { FileService } from 'src/app/core/services/file.service';
import { AddProductComponent } from '../add-product/add-product.component';
import { ErrorHandler } from 'src/app/core/utils/error-handler.util';
import { CatalogControllerService } from '@soctrip/angular-stock-service';
import { CustomTranslateService } from 'src/app/core/services/custom-translate.service';
import { Toast } from 'src/app/core/utils/toast.util';
import { AppService } from 'src/app/core/services/app.service';

@Component({
  selector: 'app-pro-cat-details',
  templateUrl: './category-details.component.html',
  styleUrls: ['./category-details.component.scss'],
})
export class CategoryDetailsComponent implements OnChanges {
  @Input() categoryNode!: Category;
  @ViewChild(AddProductComponent) addProduct: AddProductComponent;
  isAddDialogPending = false;

  toast = inject(Toast);
  catalogService = inject(CatalogControllerService);
  confirmationService = inject(ConfirmationService);
  fileService = inject(FileService);
  errorHandler = inject(ErrorHandler);
  translator = inject(CustomTranslateService);

  categoryPrefix = 'section-product-n-food-category.';
  actionPrefix = 'section-action.';
  categoryDescription = 'section-product-n-food-category.text-description.';
  statusPrefix = 'section-status.';

  breadCrumbItems: MenuItem[] = [];

  productData: Product[] = [];
  isDialogVisible = false;

  selectedProducts: Product[] = [];
  bufferSelectedId: any[] = [];
  isDataLoading = false;

  editData: { addData: any[]; deleteData: any[] } = {
    addData: [],
    deleteData: [],
  };
  shopId: string = '';

  constructor(private appService: AppService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.appService.getShopData().subscribe((data) => {
      if (data?.id) {
        this.shopId = data.id;
        this.handleDataChange();
      }
    });
  }

  handleDataChange() {
    this.resetAllData();
    this.getCategoriesDetailsById();
    this.getBreadCrumb();
  }

  getCategoriesDetailsById(category: Category = this.categoryNode) {
    if (!category?.id) return;
    this.isDataLoading = true;
    this.catalogService.catalogsObjectIdIdProductsGet(this.shopId, this.categoryNode.id as string).subscribe({
      next: (response: any) => {
        const { data, error, success } = response;
        if (success) {
          this.productData = data.data;
          this.checkSelectedIdList();
        } else this.errorHandler.handle(error);
      },
      error: (error: any) => {
        this.resetAllData();
        this.errorHandler.handle(error);
      },
      complete: () => (this.isDataLoading = false),
    });
  }

  /**
   * This method is deprecated, as the added products are not belong to many categories anymore. Re-check carefully before removing this method.
   */
  checkSelectedIdList() {
    this.selectedProducts = [];
    const idList = this.bufferSelectedId;
    for (const product of this.productData) if (idList.includes(product.id)) this.selectedProducts.push(product);
  }

  /**
   * Prepare for the deletion list
   */
  getSelectionChange(event: any) {
    let idList = this.bufferSelectedId;
    for (const product of this.selectedProducts) {
      if (!idList.includes(product.id)) idList.push(product.id);
    }

    for (const product of this.productData) {
      if (!this.selectedProducts.includes(product) && idList.includes(product.id))
        idList.splice(idList?.indexOf(product.id), 1);
    }

    this.editData.deleteData = [...idList];
  }

  getImgUrl(id: string): string {
    return this.fileService.getImgWebp(id);
  }

  confirmAdd() {
    if (!this.categoryNode.id) return;

    this.isAddDialogPending = true;
    this.catalogService.catalogsIdProductsPost(this.editData, this.categoryNode.id).subscribe({
      next: (response: any) => {
        const { error, success } = response;
        if (success) {
          const title = `${this.statusPrefix}success`;
          const message = `${this.categoryDescription}updated-product`;
          this.toast.success(this.translator.sentenceCase(title), this.translator.sentenceCase(message));
        } else this.errorHandler.handle(error);
      },
      error: (error: any) => {
        this.errorHandler.handle(error);
        this.completeAdd();
      },
      complete: () => {
        this.editData.addData = [];
        this.completeAdd();
      },
    });
  }

  completeAdd() {
    this.isAddDialogPending = false;
    this.isDialogVisible = false;
    this.addProduct.resetData();
    this.addProduct.getPageStockProduct();
    this.getCategoriesDetailsById();
  }

  handleDelete(event: any, product: Product) {
    const message = `${this.categoryDescription}delete-product-confirmation`;
    const header = `${this.categoryDescription}delete-confirmation`;
    this.confirmationService.confirm({
      message: this.translator.sentenceCase(message),
      header: this.translator.sentenceCase(header),
      icon: 'pi pi-info-circle',
      accept: () => this.confirmDelete(product),
      reject: () => {},
    });
  }

  confirmDelete(product: Product) {
    if (!this.categoryNode.id) return;
    this.isDataLoading = true;
    if (!this.selectedProducts.length) this.selectedProducts.push(product);
    const deleteData = this.selectedProducts.map((p: any) => p.id);

    this.editData = { addData: [], deleteData: deleteData };
    this.catalogService.catalogsIdProductsPost(this.editData, this.categoryNode.id).subscribe({
      next: (response: any) => {
        const { error, success } = response;
        if (success) {
          this.handleDataChange();

          const title = `${this.statusPrefix}success`;
          const message = `${this.categoryDescription}deleted-product`;
          this.toast.success(this.translator.sentenceCase(title), this.translator.sentenceCase(message));
        } else this.errorHandler.handle(error);
      },
      error: (error: any) => {
        this.errorHandler.handle(error);
        this.resetAllData();
      },
      complete: () => {
        this.resetAllData();
        this.addProduct.resetData();
        this.addProduct.getPageStockProduct();
      },
    });
  }

  showAddProductDialog() {
    this.isDialogVisible = true;
  }

  cancelAddProduct() {
    this.isDialogVisible = false;
  }

  resetAllData() {
    this.bufferSelectedId = [];
    this.selectedProducts = [];
    this.productData = [];
    this.editData = { addData: [], deleteData: [] };
  }

  /**
   * Get breadcrumbs from the root node to the selected node
   * @param node the selected node
   */
  getBreadCrumb(node: Category = this.categoryNode) {
    const url = `/product-categories/detail`;
    this.breadCrumbItems = [
      {
        label: node?.name,
        routerLink: `${url}/${node?.id}`,
        title: node?.name,
      },
    ];
    let parentNode = node?.parent;
    while (parentNode) {
      this.breadCrumbItems.unshift({
        label: parentNode?.name,
        routerLink: `${url}/${parentNode?.id}`,
        title: parentNode?.name,
      });
      parentNode = parentNode?.parent;
    }
  }

  getCurrencyCode() {
    return 'USD';
  }
}
