import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ProductSummary } from '../models/interfaces/product/product-summary';
import { DataList, ResponseType } from '../models/interfaces/common';
import { AttributeGet } from '../models/interfaces/product/product-detail';
import { ExportAttributeEnum, ExportTypeEnum } from '../enum/product.enum';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private httpClient: HttpClient) {}

  shopsMyShopSearchGet(shopId: string, pageNum: number, pageSize: number, filters: string, sort?: string) {
    return this.httpClient.get(
      `${
        environment.BE_URL
      }stock/shops/my-shop/${shopId}?pageNum=${pageNum}&pageSize=${pageSize}&filters=${encodeURIComponent(
        filters,
      )}&sort=${encodeURIComponent(sort || '')}`,
    );
  }

  getEligibleProductsFlashDeal(
    shopId: string,
    flashDealId: string,
    pageNum: number,
    pageSize: number,
    filters: string,
    sort?: string,
  ) {
    return this.httpClient.get<ResponseType<DataList<ProductSummary[]>>>(
      `${
        environment.BE_URL
      }stock/shops/my-shop/${shopId}/product-flash-deal?flashDealId=${flashDealId}&pageNum=${pageNum}&pageSize=${pageSize}&filters=${encodeURIComponent(
        filters,
      )}&sort=${encodeURIComponent(sort || '')}`,
    );
  }

  getEligibleProductsVoucher(
    shopId: string,
    flashDealId: string,
    pageNum: number,
    pageSize: number,
    filters: string,
    sort?: string,
  ) {
    return this.httpClient.get<ResponseType<DataList<ProductSummary[]>>>(
      `${
        environment.BE_URL
      }stock/shops/my-shop/${shopId}/eligible-products?voucher_id=${flashDealId}&pageNum=${pageNum}&pageSize=${pageSize}&filters=${encodeURIComponent(
        filters,
      )}&sort=${encodeURIComponent(sort || '')}`,
    );
  }

  getAttributes(filter: string) {
    return this.httpClient.get<ResponseType<DataList<AttributeGet[]>>>(
      `${environment.BE_URL}stock/product-attributes/search?pageNum=0&pageSize=10&filters=${encodeURIComponent(
        filter,
      )}`,
    );
  }

  downloadExcelTemplate(shopId: string) {
    return this.httpClient.get(`${environment.BE_URL}stock/products/${shopId}/export-excel`, { responseType: 'blob' });
  }

  exportProductsToExcel(shopId: string, type: ExportTypeEnum, attribute: ExportAttributeEnum, categoryId?: string) {
    let uri = `${environment.BE_URL}stock/products/${shopId}/export-data-excel?products=${type}&attributes=${attribute}`;
    if (categoryId) {
      uri += `&shopCatalogId=${categoryId}`;
    }
    return this.httpClient.get(uri, { responseType: 'blob' });
  }
}
