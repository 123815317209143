<div>
  <!-- HEADER -->
  <div class="flex items-center gap-4 my-4 mt-0 {{ isViewMode ? 'hidden' : '' }}">
    <a [routerLink]="['../']" class="border bg-white rounded-lg w-8 h-8 flex items-center justify-center">
      <span class="{{ SoctripIcons.ARROW_LEFT }} text-xl"></span>
    </a>
    <h2 class="font-semibold text-lg">
      {{ actionPrefix + (productId === "new" ? "create" : "edit") | translate | sentenceCase }}
      {{ "common.product" | translate }}
    </h2>
  </div>
  <!-- MAIN -->
  <main *ngIf="!isFetching; else fetching">
    <form #form="ngForm" class="flex flex-col gap-4">
      <!-- GENERAL INFORMATION-->
      <app-general-info
        ngModelGroup="general_info"
        [productForm]="productForm"
        [productData]="productData"
        [(attachments)]="attachmentsDisplay"
        [categories]="categoriesDisplay"
        [isViewMode]="isViewMode"
      ></app-general-info>

      <!-- DESCRIPTION -->
      <div class="bg-white rounded-lg border px-4 py-6">
        <h3 class="mb-6 font-semibold text-gray-900">
          {{ managementPrefix + "product-description" | translate | sentenceCase }}
        </h3>
        <div class="flex flex-col gap-2 break-words">
          <app-editor-custom
            [productForm]="productForm"
            [isViewMode]="isViewMode"
            [(isValidDes)]="isValidDes"
          ></app-editor-custom>
        </div>
      </div>

      <!-- PRODUCT DETAIL-->
      <app-product-attribute
        ngModelGroup="product_attribute"
        [productForm]="productForm"
        [isViewMode]="isViewMode"
      ></app-product-attribute>

      <!-- PRICING -->
      <app-product-pricing
        ngModelGroup="product_pricing"
        [productForm]="productForm"
        [attachmentsDisplay]="attachmentsDisplay"
        [productData]="productData"
        [isViewMode]="isViewMode"
        [isCreateMode]="isCreateMode"
      ></app-product-pricing>

      <!-- SHIPMENT SETTING-->
      <app-shipping-setting
        ngModelGroup="product_shipping_setting"
        [productForm]="productForm"
        [isViewMode]="isViewMode"
      ></app-shipping-setting>

      <!-- PRODUCTS RELATED-->
      <div class="bg-white rounded-lg border px-4 py-6">
        <h3 class="mb-6 font-semibold text-gray-900">
          {{ managementPrefix + "products-related" | translate | sentenceCase }}
        </h3>
        <div class="flex flex-col gap-2">
          <label class="text-gray-700 text-sm mb-4" htmlFor="description">
            {{ managementPrefix + "text-description.products-related" | translate | sentenceCase }}
          </label>
          <app-products-related
            [labelAddProduct]="managementPrefix + 'add-product-related' | translate | sentenceCase"
            [(selectedProducts)]="productForm.products_related"
            [isViewMode]="isViewMode"
          ></app-products-related>
        </div>
      </div>

      <!-- ADD-ON DEAL -->
      <div class="bg-white rounded-lg border px-4 py-6">
        <h3 class="mb-6 font-semibold text-gray-900">
          {{ managementPrefix + "add-on-deal" | translate | sentenceCase }}
        </h3>
        <div class="flex flex-col gap-2">
          <label class="text-gray-700 text-sm mb-4" htmlFor="description">
            {{ managementPrefix + "text-description.add-on-deal" | translate | sentenceCase }}
          </label>
          <app-products-related
            [labelAddProduct]="managementPrefix + 'add-product' | translate | sentenceCase"
            [(selectedProducts)]="productForm.products_combo"
            [isViewMode]="isViewMode"
          ></app-products-related>
        </div>
      </div>

      <!-- PUBLISH-->
      <div class="flex justify-between items-center {{ isViewMode ? 'hidden' : '' }}">
        <div class="flex items-center gap-4">
          <div class="flex gap-2 items-center">
            <p-checkbox
              [(ngModel)]="productForm.is_public"
              [binary]="true"
              [ngModelOptions]="{ standalone: true }"
            ></p-checkbox>
            <label class="text-sm text-gray-700 font-medium">
              {{ descriptionPrefix + "publish-product" | translate | sentenceCase }}
            </label>
          </div>

          <div *ngIf="productForm.category?.type === 'E_TICKET'" class="flex gap-2 items-center">
            <p-checkbox
              [(ngModel)]="productForm.auto_confirm"
              [binary]="true"
              [ngModelOptions]="{ standalone: true }"
            ></p-checkbox>
            <label class="text-sm text-gray-700 font-medium">
              {{ descriptionPrefix + "auto-confirm" | translate | sentenceCase }}
            </label>
          </div>
        </div>
        <div class="flex gap-2">
          <app-button
            (onClick)="onBack()"
            [label]="actionPrefix + 'cancel' | translate | sentenceCase"
            variant="outline"
          >
          </app-button>
          <app-button
            (onClick)="_onSave()"
            [disabled]="!form?.valid || productForm.attachments.length === 0 || !isValidDes"
            [label]="actionPrefix + 'save' | translate | sentenceCase"
            type="submit"
          >
          </app-button>
        </div>
      </div>
    </form>
  </main>
</div>

<p-dialog
  [visible]="isUploadImages || isUpdating"
  [closable]="false"
  [draggable]="false"
  [modal]="true"
  [style]="{ 'min-width': '30vw' }"
>
  <div class="-mt-1">
    <div class="flex items-center justify-center gap-6 mb-6">
      <p *ngIf="isUploadImages">
        {{ descriptionPrefix + "in-uploading-image" | translate | sentenceCase }}
      </p>
      <p *ngIf="isUpdating">
        {{ descriptionPrefix + "in-the-process-of" | translate | sentenceCase }}
        {{ actionPrefix + (productId === "new" ? "creating" : "updating") | translate }}
        {{ "common.product" | translate }}
      </p>
    </div>
    <div class="w-4/5 mx-auto">
      <p-progressBar mode="indeterminate" [style]="{ height: '4px' }"></p-progressBar>
    </div>
  </div>
</p-dialog>

<ng-template #fetching>
  <div class="flex items-center min-h-[calc(100vh-_72px)] justify-center rounded-md border bg-white">
    <p-progressSpinner [style]="{ width: '80px' }"></p-progressSpinner>
  </div>
</ng-template>
