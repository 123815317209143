import { Injectable } from '@angular/core';
import { RoleEnum } from '../enum/role.enum';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  private userRolesSubject: BehaviorSubject<RoleEnum[]> = new BehaviorSubject<
    RoleEnum[]
  >([]);

  userRoles$ = this.userRolesSubject.asObservable();

  setRoles(roles: RoleEnum[]) {
    this.userRolesSubject.next(roles);
  }

  hasRole(roles: RoleEnum[]) {
    return this.userRolesValue.some((item) => roles.includes(item));
  }

  public get userRolesValue(): RoleEnum[] {
    return this.userRolesSubject.value;
  }
}
