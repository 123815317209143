import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FlagsDropdownComponent } from './components/dropdowns/flags-dropdown/flags-dropdown.component';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { ButtonComponent } from './components/button/button.component';
import { CoreModule } from '../core/core.module';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ButtonModule } from 'primeng/button';
import { FileUploadModule } from 'primeng/fileupload';
import { ImageModule } from 'primeng/image';
import { PanelComponent } from './components/panel/panel.component';
import { CustomImageComponent } from './components/custom-image/custom-image.component';
import { SidebarModule } from 'primeng/sidebar';
import { HeaderComponent } from './components/header/header.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormSectionComponent } from './components/form-section/form-section.component';
import { AddProductComponent } from './components/add-product/add-product.component';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { PaginatorModule } from 'primeng/paginator';
import { TreeSelectModule } from 'primeng/treeselect';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { SkeletonModule } from 'primeng/skeleton';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { TabViewComponent } from './components/tabview/tabview.component';
import { ToastMessageComponent } from './components/toast-message/toast-message.component';
import { TableEmptyComponent } from './components/table-empty/table-empty.component';
import { AppLayoutComponent } from './layouts/app-layout/app-layout.component';
import { SiteLayoutComponent } from './layouts/site-layout/site-layout.component';
import { FrozenColStyleDirective } from './directives/frozen-col-style.directive';
import { AsteriskDirective } from './directives/asterisk.directive';
import { SentenceCasePipe } from '../core/pipes/sentence-case.pipe';
import { ShortNumberPipe } from '../core/pipes/short-number.pipe';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TooltipModule } from 'primeng/tooltip';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { CalendarModule } from 'primeng/calendar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { HeaderNotificationsComponent } from './components/header/notifications/notifications.component';
import { BadgeModule } from 'primeng/badge';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { MaxPriceValidatorDirective } from './directives/max-price-validator.directive';
import { CurrencyConversionPipe } from '@soctrip-common/app-security';
import { DateFormatPipe, DatetimeFormatterModule } from '@soctrip-common/datetime-formatter';
import { FloorPricePipe } from '../core/pipes/floor-price.pipe';
import { SelectTimeComponent } from './components/select-time/select-time.component';

@NgModule({
  declarations: [
    FlagsDropdownComponent,
    ButtonComponent,
    SidebarComponent,
    PanelComponent,
    CustomImageComponent,
    HeaderComponent,
    FormSectionComponent,
    DragAndDropDirective,
    FrozenColStyleDirective,
    AddProductComponent,
    PaginatorComponent,
    TabViewComponent,
    ToastMessageComponent,
    TableEmptyComponent,
    AppLayoutComponent,
    SiteLayoutComponent,
    AsteriskDirective,
    ShortNumberPipe,
    NotFoundComponent,
    CalendarComponent,
    HeaderNotificationsComponent,
    WelcomeComponent,
    DragAndDropDirective,
    UploadFileComponent,
    MaxPriceValidatorDirective,
    FloorPricePipe,
    SelectTimeComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    DropdownModule,
    FormsModule,
    CoreModule,
    ButtonModule,
    FileUploadModule,
    ImageModule,
    SidebarModule,
    TableModule,
    DialogModule,
    PaginatorModule,
    TreeSelectModule,
    InputTextModule,
    TranslateModule,
    ToastModule,
    SkeletonModule,
    ConfirmDialogModule,
    TooltipModule,
    CalendarModule,
    OverlayPanelModule,
    BadgeModule,
    CurrencyConversionPipe,
    DatetimeFormatterModule
  ],
  exports: [
    FlagsDropdownComponent,
    ButtonComponent,
    SidebarComponent,
    PanelComponent,
    CustomImageComponent,
    HeaderComponent,
    TranslateModule,
    FormSectionComponent,
    DragAndDropDirective,
    FrozenColStyleDirective,
    AddProductComponent,
    SentenceCasePipe,
    PaginatorComponent,
    TabViewComponent,
    ToastMessageComponent,
    TableEmptyComponent,
    AsteriskDirective,
    ShortNumberPipe,
    CalendarComponent,
    DragAndDropDirective,
    UploadFileComponent,
    MaxPriceValidatorDirective,
    CurrencyConversionPipe,
    DateFormatPipe,
    FloorPricePipe,
    SelectTimeComponent
  ],
})
export class SharedModule {}
